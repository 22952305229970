import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const StaticImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(
    graphql`
      query {
        django2 {
          allCollections(slug: "static") {
            edges {
              node {
                dressSet(name: "vkontaktu") {
                  edges {
                    node {
                      name
                      dresspictureSet {
                        edges {
                          node {
                            originalPhoto
                            originalPhotoSharp {
                              childImageSharp {
                                fluid {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <Img
      fluid={
        data.django2.allCollections.edges[0].node.dressSet.edges[0].node
          .dresspictureSet.edges[0].node.originalPhotoSharp.childImageSharp
          .fluid
      }
      alt={data.django2.allCollections.edges[0].node.dressSet.edges[0].node.name}
    />
  );
};
export default StaticImage;
